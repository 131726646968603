<template>
  <div class="set-content">
    <div class="o-title">
      <span>添加图片</span>
      <span class="prompt">最多5张</span>
    </div>
    <div class="img-list" v-if="posterInfo.length > 0">
      <div class="img-item" v-for="(item, index) in posterInfo" :key="`posterInfo_${index}`">
        <div class="img-head">
          <div class="img-title">图片{{ index + 1 }}</div>
          <el-link :underline="false" type="primary" @click="delBtn(index)">删除</el-link>
        </div>
        <div class="img-box">
          <img :src="item" alt="" />
        </div>
      </div>
    </div>
    <el-upload class="upload-demo" :action="uploadUrl" :headers="headersToken" :on-success="handleCoverSuccess" :before-upload="beforeCoverUpload" :show-file-list="false" :data="multipartFile" name="image" accept="image/jpg, image/jpeg, image/png">
      <el-button class="button-w-b" size="large" style="width: 100%; margin-top: 10px" icon="el-icon-plus">添加图片</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  components: {},
  props: {
    posterInfo: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      hostUrl: localStorage.getItem("hosturl"),
      headersToken: {
        Authorization: localStorage.getItem("studentToken"),
      },
      uploadUrl: localStorage.getItem("hosturl") + "stuOp/dzPosterUploadImg",
      multipartFile: {
        type: 2,
      },
    };
  },
  computed: {},
  methods: {
    beforeCoverUpload(file) {
      if (this.posterInfo.length > 4) {
        this.$message.warning("Banner最多上传五张");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      } else {
        const isSize = new Promise((resolve, reject) => {
          const width = 1920;
          const height = 630;
          const _URL = window.URL || window.webkitURL;
          const img = new Image();
          img.onload = () => {
            const valid = img.width === width && img.height === height;
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(file);
        }).then(
          () => {
            return file;
          },
          () => {
            this.$message.warning("图片尺寸限制为1920px x 630px，大小不可超过2MB");
            return Promise.reject();
          }
        );
        return isSize;
      }
    },
    handleCoverSuccess(res, file) {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.$emit("uploadCarousel", res.data);
      } else {
        this.$message.error(res.msg);
      }
    },
    delBtn(index) {
      this.posterInfo.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.set-content {
  margin: 20px 10px 0;
  /*background: #F0F2F5;*/
  /*padding: 20px 10px;*/
  border-radius: 4px;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .prompt {
    font-size: 12px;
    color: #9797a1;
  }
}
.upload-demo {
  :deep(.el-upload) {
    width: 100%;
  }
}
.img-list {
  .img-item {
    background: #f0f2f5;
    padding: 20px;
    margin-bottom: 20px;
    font-size: 12px;
    .img-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #606266;
    }
    .img-box {
      margin-top: 20px;
      width: 200px;
      height: 62.625px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
