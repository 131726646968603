<template>
  <div>
    <div class="module-title">商品列表</div>
    <div class="good-list" v-if="goodList.length > 0">
      <div class="good-item" :class="{ current: goodsInfo.find((val) => val.id === item.id) }" v-for="(item, index) in goodList" :key="`goodList_${index}`" @click="addGood(item)">
        <div class="good-img">
          <img :src="item.image_url" style="width: 100%; height: 100%" />
        </div>
        <div class="good-detail">
          <div class="text text-overflow-2">{{ item.goods_name }}</div>
          <div class="info">
            <div class="price">
              <div class="red">￥{{ item.goods_zhekou_price }}</div>
              <div class="grey">￥{{ item.goods_price }}</div>
            </div>
            <div class="operate">
              <el-link :underline="false" type="primary" @click.stop="seeBtn(item.id)">查看</el-link>
              <el-link :underline="false" type="primary" @click.stop="changeBtn(item.id)">修改</el-link>
              <el-link :underline="false" type="danger" @click.stop="delBtn(item.id)">删除</el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-good" v-else>
      <img src="../../../assets/images/no-goods.png" alt="" />
      <div class="text">还没有商品呢～</div>
    </div>
    <el-button class="button-w-b" size="large" style="margin: 40px 10px 0; width: calc(100% - 20px)" @click="addGoods" icon="el-icon-plus">添加商品</el-button>
    <div class="goods-add-dialog">
      <el-dialog :visible="dialogFormVisible" title="发布商品" width="990px" top="5vh" custom-class="stu-dialog" :close-on-click-modal="false" @close="resetForm('ruleFormRef')">
        <el-scrollbar style="height: 600px">
          <el-form :model="goodDetail" label-width="100px" size="large" ref="ruleFormRef" :rules="rules" class="demo-ruleForm">
            <div class="form-box">
              <div class="form-title">基本信息</div>
              <el-form-item label="商品标题" prop="goods_name">
                <el-input v-model="goodDetail.goods_name" style="width: 320px" maxlength="120" show-word-limit />
              </el-form-item>
              <el-form-item label="商品价格" prop="goods_price">
                <el-input-number v-model="goodDetail.goods_price" :precision="2" :controls="false" :min="goodDetail.goods_zhekou_price ? goodDetail.goods_zhekou_price : 0.01" :max="99999999.99" class="number-input" style="width: 320px" />
              </el-form-item>
              <el-form-item label="折扣价" prop="goods_zhekou_price">
                <el-input-number v-model="goodDetail.goods_zhekou_price" :precision="2" :controls="false" :min="0.01" :max="goodDetail.goods_price ? goodDetail.goods_price : 99999999.99" class="number-input" style="width: 320px" />
              </el-form-item>
              <el-form-item label="行业分类" prop="category_id">
                <el-select v-model="goodDetail.category_id" placeholder="请选择" style="width: 320px">
                  <el-option v-for="(classItem, classIndex) in classList" :show-all-levels="false" :label="classItem.name" :value="classItem.id" :key="`classList_${classIndex}`" />
                </el-select>
              </el-form-item>
              <el-form-item label="店铺分类" prop="store_class_id">
                <el-cascader v-model="goodDetail.store_class_id" style="width: 320px" :show-all-levels="false" :options="storeClassList" :props="defaultParams" @change="handleChange" />
              </el-form-item>
            </div>
            <div class="form-box" style="padding-bottom: 20px">
              <el-form-item label="商品图片" prop="goods_image" style="margin-bottom: 10px" class="top10"></el-form-item>
              <div class="upload-box">
                <div class="img-box">
                  <div class="img-list" v-if="goodDetail.goods_image.length > 0">
                    <div class="img-item" v-for="(item, index) in goodDetail.goods_image" :key="`img_${index}`">
                      <img :src="item" alt="" />
                      <i class="del-btn iconfont" @click="delImgBtn(index)">&#xec7b;</i>
                    </div>
                  </div>
                </div>
                <el-upload class="upload-file" :action="uploadUrl" :headers="headersToken" name="file" multiple :limit="8" :show-file-list="false" :on-exceed="handleExceed" :on-success="handleCoverSuccess" :before-upload="beforeCoverUpload" list-type="picture" :file-list="goodImgList" accept="image/jpg, image/jpeg, image/png">
                  <div class="upload-btn">
                    <i class="el-icon-plus"></i>
                    <div class="text">添加图片</div>
                  </div>
                </el-upload>
              </div>
              <div class="prompt-text">图片至少上传3张，最多8张，仅支持jpg、jpeg、png格式，大小 不能超过1M！</div>
            </div>
            <div class="form-box" style="padding-bottom: 20px">
              <el-form-item label="视频封面" prop="goods_cover" style="margin-bottom: 10px" class="top10" :rules="goodDetail.goods_cover.length || goodDetail.goods_video.length ? [{ required: true, message: '请上传视频封面', trigger: 'change' }] : [{ required: false }]">
                <div style="width: 90px; height: 20px; background: #f0f2f5; z-index: 999" v-if="goodDetail.goods_cover.length || (!goodDetail.goods_cover.length && !goodDetail.goods_video)"></div>
              </el-form-item>
              <div class="upload-box">
                <div class="img-box">
                  <div class="img-list" v-if="goodDetail.goods_cover.length > 0">
                    <div class="img-item" v-for="(item, index) in goodDetail.goods_cover" :key="`img_${index}`">
                      <img :src="item.url" v-if="item.url" />
                      <img :src="item" alt="" v-else />
                      <i class="del-btn iconfont" @click="delCoverImg(index)">&#xec7b;</i>
                    </div>
                  </div>
                </div>
                <el-upload class="upload-file" :action="uploadUrl" :headers="headersToken" :file-list="goodDetail.goods_cover" name="file" :show-file-list="false" :on-success="handleVideoImgSuccess" :before-upload="beforeVideoImgUpload" accept=".jpg, .png, .gif, .bmp">
                  <div class="upload-btn">
                    <i class="el-icon-plus"></i>
                    <div class="text">添加图片</div>
                  </div>
                </el-upload>
              </div>

              <div class="prompt-text">图片仅上传1张，仅支持jpg、png、gif、bmp格式，大小不能超过5M！</div>
            </div>
            <div class="form-box" style="padding-bottom: 20px">
              <el-form-item label="商品视频" prop="goods_video" style="margin-bottom: 10px; position: relative" class="top10" :rules="goodDetail.goods_cover.length || goodDetail.goods_video.length ? [{ required: true, message: '请上传商品视频', trigger: 'change' }] : [{ required: false }]">
                <el-input v-model="goodDetail.goods_video" style="display: none" />
                <div style="width: 90px; height: 20px; background: #f0f2f5; z-index: 999" v-if="goodDetail.goods_video || (!goodDetail.goods_cover.length && !goodDetail.goods_video)"></div>
              </el-form-item>
              <div class="upload-box">
                <div class="img-box">
                  <div class="img-list" v-if="goodDetail.goods_video.length > 0">
                    <div class="img-item" v-for="(item, index) in goodDetail.goods_video" :key="`img_${index}`">
                      <video :src="item.url" v-if="item.url" style="width: 100%; height: 100%" @click="videoPreview(item.url)"></video>
                      <video :src="item" alt="" v-else style="width: 100%; height: 100%" @click="videoPreview(item)"></video>
                      <i class="del-btn iconfont" @click="delVideo(index)">&#xec7b;</i>
                    </div>
                  </div>
                </div>
                <el-upload class="upload-file" :action="videoUploadUrl" :headers="headersToken" :file-list="goodDetail.goods_video" name="file" :show-file-list="false" :on-success="handleVideoSuccess" :before-upload="beforeVideoUpload" accept=".mp4, .mov">
                  <div class="upload-btn">
                    <i class="el-icon-plus"></i>
                    <div class="text">添加视频</div>
                  </div>
                </el-upload>
              </div>
              <div style="width: 80px; margin: -6px 0 0 30px">
                <el-progress :percentage="showProgress" :show-text="false"></el-progress>
              </div>
              <div class="prompt-text">视频时长30秒，视频格式为.mp4/.mov且大小在500MB内，清晰度≥480p。</div>
              <!-- <goods-video :video-src="goodDetail.goods_video" :id.number="goodDetail.id" :has-label="false" @change="setGoodsVideo"></goods-video> -->
            </div>
            <div class="form-box" style="padding-bottom: 20px">
              <el-form-item label="商品详情" prop="goods_content" style="margin-bottom: 10px" class="top10" label-width="234px">
                <template #label>
                  <span>商品详情<span style="font-size: 12px; color: #aeb2b9; margin-left: 6px">(图片单次最多上传20张)</span></span>
                </template>
              </el-form-item>
              <div style="padding: 0 20px" v-if="dialogFormVisible">
                <vue-tinymce ref="tinymceItem" :setup="setup" :setting="setting" v-model="goodDetail.goods_content"></vue-tinymce>
              </div>
            </div>
          </el-form>
        </el-scrollbar>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="resetForm('ruleFormRef')">取消</el-button>
            <el-button type="primary" @click="submitForm('ruleFormRef')">确定</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog :visible="videoDialog" title="视频预览" width="400px" top="5vh" @close="videoDialog = false">
        <video :src="currentVideo" style="width: 100%; height: 100%" controls></video>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { decorateCategoryList, stuDecorateStoreClassList, stuDecorateUploadGoodsDetImg, stuDecorateStuSaveGoods, stuDecorateStuGoodsList, stuDecorateStuGoodsDet, stuDecorateDelStuGoods } from "@/utils/newDecorate";
import GoodsVideo from "@/components/student/dataSelection/components/GoodsVideo.vue";
import { adminGoodUploadImage } from "@/utils/apis.js";

export default {
  name: "Good",
  components: {
    GoodsVideo,
  },
  props: {
    goodsInfo: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dialogFormVisible: false,
      goodDetail: {
        id: null,
        goods_name: "",
        goods_price: void 0,
        goods_zhekou_price: void 0,
        category_id: null,
        store_class_id: null,
        goods_image: [],
        goods_cover: [],
        goods_video: [],
        goods_content: "",
      },
      rules: {
        goods_name: [
          { required: true, message: "请输入商品标题", trigger: "blur" },
          { min: 1, max: 120, message: "1—120个字", trigger: "blur" },
        ],
        goods_price: [{ required: true, message: "请输入价格", trigger: "blur" }],
        goods_zhekou_price: [{ required: true, message: "请输入折扣价", trigger: "blur" }],
        category_id: [{ required: true, message: "请选择行业分类", trigger: "change" }],
        store_class_id: [{ required: true, message: "请选择店铺分类", trigger: "change" }],
        d_t_score: [{ required: true, message: "请输入分值", trigger: "blur" }],
        goods_image: [
          { required: true, message: "请选择商品图片", trigger: "change" },
          { validator: this.checkImg, trigger: "blur" },
        ],
        goods_content: [{ required: true, message: "请填写商品详情", trigger: "blur" }],
      },
      defaultParams: {
        label: "store_class_name",
        value: "id",
        children: "children",
        expandTrigger: "hover",
      },
      classList: [],
      storeClassList: [],
      goodList: [],
      headersToken: {
        Authorization: localStorage.getItem("studentToken"),
      },
      uploadUrl: localStorage.getItem("hosturl") + "stuOp/uploadGoodsImg",
      videoUploadUrl: localStorage.getItem("hosturl") + "stuOp/uploadGoodsVideo",
      goodImgList: [],
      hosturl: localStorage.getItem("hosturl"),
      setting: {
        menubar: false,
        icons: "custom",
        plugins: "lists advlist emoticons fullscreen table insertdatetime wordcount customImage",
        toolbar: ["undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|", "customImage emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|"],
        language: "zh_CN", //本地化设置
        height: 1000,
      },
      showProgress: 0,
      timer: null,
      currentVideo: "",
      videoDialog: false,
    };
  },
  computed: {},
  methods: {
    videoPreview(url) {
      console.log({ url });
      this.currentVideo = url;
      this.videoDialog = true;
    },
    addGoods() {
      this.dialogFormVisible = true;
    },
    checkImg(rule, value, callback) {
      if (!value.length) {
        return callback(new Error("请选择商品图片"));
      } else if (value.length < 3) {
        return callback(new Error("商品图片不得小于3张"));
      } else if (value.length > 8) {
        return callback(new Error("商品图片不得大于8张"));
      }
      callback();
    },
    setGoodsVideo(data) {
      goodDetail.goods_video = data;
    },
    handleChange(val) {
      let classValue = val[val.length - 1];
      this.goodDetail.store_class_id = classValue;
    },
    getAllGoodList() {
      try {
        stuDecorateStuGoodsList({ type: 1 }).then(({ data, msg }) => {
          this.goodList = data.list;
        });
      } catch (e) {
        this.$message.warning(e);
      }
    },
    // 添加商品到左侧
    addGood(val) {
      this.$emit("chooseGood", val);
    },
    seeBtn(id) {
      const href = this.$router.resolve({
        path: "/newDecorator/detail",
        query: {
          id: id,
        },
      });
      window.open(href.href, "_blank");
    },
    changeBtn(id) {
      try {
        stuDecorateStuGoodsDet({ id }).then(({ data, msg }) => {
          this.goodDetail.id = data.id;
          this.goodDetail.goods_name = data.goods_name;
          this.goodDetail.goods_price = data.goods_price;
          this.goodDetail.goods_zhekou_price = data.goods_zhekou_price;
          this.goodDetail.category_id = data.category_id;
          this.goodDetail.store_class_id = data.store_class_id;
          this.goodDetail.goods_image = data.goods_image.map((item) => item.image_url);
          this.goodDetail.goods_content = data.goods_content;
          let cover = [];
          if (data.goods_cover) cover.push({ url: data.goods_cover });
          this.goodDetail.goods_cover = cover;
          let video = [];
          if (data.goods_video) video.push({ url: data.goods_video });
          this.goodDetail.goods_video = video;
          // this.goodDetail.goods_video = data.goods_video;
          this.dialogFormVisible = true;
        });
      } catch (e) {
        this.$message.warning(e.msg);
      }
    },
    delBtn(id) {
      this.$confirm("确定删除该数据，删除之后则无法恢复？", "删除数据", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          try {
            stuDecorateDelStuGoods({ id: id }).then(({ data, msg }) => {
              this.$message.success(msg);
              this.getAllGoodList();
              this.$emit("getStuGoods");
            });
          } catch (e) {
            this.$message.error(e.msg);
          }
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 删除图片
    delImgBtn(index) {
      this.goodDetail.goods_image.splice(index, 1);
      this.goodImgList.splice(index, 1);
    },
    // 删除封面
    delCoverImg(index) {
      this.goodDetail.goods_cover.splice(index, 1);
    },
    // 删除视频
    delVideo(index) {
      this.goodDetail.goods_video.splice(index, 1);
      this.showProgress = 0;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addForm(formName);
        } else {
          console.log("error submit!", fields);
        }
      });
    },
    addForm(formName) {
      try {
        let params = {
          goods_name: this.goodDetail.goods_name,
          goods_price: this.goodDetail.goods_price,
          goods_zhekou_price: this.goodDetail.goods_zhekou_price,
          category_id: this.goodDetail.category_id,
          store_class_id: this.goodDetail.store_class_id,
          goods_image: this.goodDetail.goods_image,
          goods_cover: this.goodDetail.goods_cover[0]?.url ? this.goodDetail.goods_cover[0]?.url : this.goodDetail.goods_cover[0] ? this.goodDetail.goods_cover[0] : "",
          goods_content: this.goodDetail.goods_content,
          // goods_video: this.goodDetail.goods_video,
          goods_video: this.goodDetail.goods_video[0]?.url ? this.goodDetail.goods_video[0]?.url : this.goodDetail.goods_video[0] ? this.goodDetail.goods_video[0] : "",
        };
        if (this.goodDetail.id) {
          params.id = this.goodDetail.id;
        }
        stuDecorateStuSaveGoods(params).then(({ code, data, msg }) => {
          if (code !== 200) return;
          this.$message({
            message: msg,
            type: "success",
            duration: 1000,
            onClose: () => {
              this.resetForm(formName);
              this.getAllGoodList();
            },
          });
        });
      } catch (e) {
        this.$message.error(e.message);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.goodImgList = [];
      this.goodDetail.id = null;
      this.goodDetail.goods_name = "";
      this.goodDetail.goods_price = void 0;
      this.goodDetail.goods_zhekou_price = void 0;
      this.goodDetail.category_id = null;
      this.goodDetail.store_class_id = null;
      this.goodDetail.goods_image = [];
      this.goodDetail.goods_cover = [];
      this.goodDetail.goods_video = [];
      this.goodDetail.goods_content = "";
      this.dialogFormVisible = false;
    },
    beforeVideoImgUpload(file) {
      const isLt3M = file.size / 1024 / 1024 < 5;
      if (!isLt3M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return false;
      }
      if (this.goodDetail.goods_cover.length === 1) {
        this.$message.error("只能上传1张视频封面!");
        return false;
      }
    },
    handleVideoImgSuccess(res, file) {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.goodDetail.goods_cover.push(res.data.url);
      } else {
        this.$message.error(res.msg);
      }
    },
    beforeVideoUpload(file) {
      if (this.goodDetail.goods_video.length === 1) {
        this.$message.error("只能上传1个视频文件!");
        this.showProgress = 0;
        return false;
      }
      this.showProgress = 1;
      this.timer = setInterval(() => {
        if (this.showProgress < 90) this.showProgress++;
      }, 100);
    },

    handleVideoSuccess(res, file) {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.goodDetail.goods_video.push(res.data.url);
        this.showProgress = 100;
        clearInterval(this.timer);
      } else {
        this.$message.error(res.msg);
      }
    },
    beforeCoverUpload(file) {
      if (this.goodDetail.id) {
        if (this.goodDetail.goods_image.length + this.goodImgList.length > 8) {
          this.$message.warning("当前已经选择8张了");
          return false;
        }
      }
      const isLt3M = file.size / 1024 / 1024 < 1;
      if (!isLt3M) {
        this.$message.error("上传图片大小不能超过 1MB!");
        return false;
      } else {
        const isSize = new Promise((resolve, reject) => {
          const width = 800;
          const height = 800;
          const _URL = window.URL || window.webkitURL;
          const img = new Image();
          img.onload = () => {
            const valid = img.width === width && img.height === height;
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(file);
        }).then(
          () => {
            return file;
          },
          () => {
            this.$message.warning("图片尺寸限制为800px x 800px，大小不可超过1MB");
            return Promise.reject();
          }
        );
        return isSize;
      }
    },
    handleCoverSuccess(res, file) {
      if (res.code === 200) {
        this.$message.success(res.msg);
        this.goodDetail.goods_image.push(res.data.url);
      } else {
        this.$message.error(res.msg);
      }
    },
    handleExceed(files, uploadFiles) {
      this.$message.warning(`限制是8张, 你这次选了${files.length}张, 加起来就是${files.length + uploadFiles.length}张了`);
    },
    setup(editor) {
      let that = this;
      // 自定义上传图片，多图上传
      tinymce.PluginManager.add("customImage", function addPlugin(editor) {
        editor.ui.registry.addButton("customImage", {
          icon: "customImage",
          onAction: () => {
            let imageInput = document.createElement("input");
            imageInput.setAttribute("type", "file");
            imageInput.setAttribute("multiple", "multiple");
            imageInput.setAttribute("accept", "image/*");
            document.body.appendChild(imageInput);
            // 模拟input点击事件
            let evt = new MouseEvent("click", {
              bubbles: false,
              cancelable: true,
              view: window,
            });
            imageInput.dispatchEvent(evt);
            imageInput.addEventListener("change", (e) => {
              // 判断图片大小
              for (const key in evt.target.files) {
                let item = evt.target.files[key];
                if (item.size) {
                  if (item.size / 1024 / 1024 > 10) {
                    that.$message.warning("图片上传大小应低于10M");
                    return false;
                  }
                }
              }
              let arrTmp = [];
              for (const key in evt.target.files) {
                let item = evt.target.files[key];
                if (item.lastModified) {
                  arrTmp.push(item);
                }
              }
              // 插入
              let formData = new FormData();
              if (arrTmp.length > 1) {
                arrTmp.forEach((item, index) => {
                  formData.append(`file[${index}]`, item);
                });
              } else {
                formData.append("file", evt.target.files[0]);
              }
              adminGoodUploadImage(formData).then((res) => {
                if (res.data.length > 0) {
                  res.data.forEach((item) => {
                    editor.execCommand("mceInsertContent", false, `<img src='${that.hosturl}${item}'/>`);
                  });
                }
                that.$message.success(res.msg);
              });
            });
            // 图片插入完后，remove
            imageInput.remove();
          },
        });
      });
    },
  },
  mounted() {
    decorateCategoryList()
      .then(({ data, msg }) => {
        this.classList = data;
      })
      .catch((err) => {
        this.$message.warning(err.msg);
      });
    stuDecorateStoreClassList()
      .then(({ data, msg }) => {
        this.storeClassList = data.list;
        this.storeClassList.map((one) => {
          if (!one.children.length) {
            delete one.children;
          } else {
            one.children.map((two) => {
              if (two.children) delete two.children;
            });
          }
        });
      })
      .catch((err) => {
        this.$message.warning(err.msg);
      });
    this.getAllGoodList();
  },
};
</script>

<style scoped lang="scss">
.goods-add-dialog {
  padding: 20px;
  ::v-deep .el-overlay {
    z-index: 1200 !important;
  }
}
.module-title {
  margin: 0 10px;
  line-height: 44px;
  border-bottom: 1px solid #e8e6eb;
}
.no-good {
  padding: 50px 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 154px;
  }
  .text {
    color: #d1d1d1;
    font-size: 18px;
    margin-top: 5px;
  }
}
.good-list {
  padding: 0 10px;
  .good-item {
    margin-top: 20px;
    padding: 10px;
    background: #f0f2f5;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    .good-img {
      width: 70px;
      height: 70px;
      border-radius: 4px;
      overflow: hidden;
    }
    .good-detail {
      width: 1%;
      flex: 1;
      margin-left: 10px;
      .text {
        color: #606266;
        height: 42px;
      }
      .info {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        .price {
          display: flex;
          align-items: center;
          .red {
            color: #ff3636;
          }
          .grey {
            color: #9a9a9a;
            margin-left: 4px;
            font-size: 12px;
            text-decoration: line-through;
          }
        }
        .operate {
          .el-link + .el-link {
            margin-left: 10px;
          }
        }
      }
    }
    &.current {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 4px solid #67c23a;
        border-radius: 4px;
      }
    }
  }
}
.form-box {
  background: #f0f2f5;
  padding: 20px 0 1px;
  border-radius: 4px;
  .form-title {
    width: 100px;
    text-align: right;
    padding-right: 12px;
    margin-bottom: 20px;
  }
  & + .form-box {
    margin-top: 20px;
  }
}

.upload-box {
  padding-left: 30px;
  display: flex;
}
.upload-file {
  .upload-btn {
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 4px;
    color: #c4c4c4;
    .el-icon {
      font-size: 18px;
      font-weight: 500;
    }
    .text {
      margin-top: 6px;
    }
  }
}
.img-box {
  display: flex;
  align-items: center;
  .img-list {
    display: flex;
    .img-item {
      width: 80px;
      height: 80px;
      margin-right: 20px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      .del-btn {
        position: absolute;
        top: 0;
        right: 0;
        line-height: 24px;
        width: 24px;
        text-align: center;
        color: #ff2424;
        background: #fff;
        cursor: pointer;
      }
    }
  }
}
.prompt-text {
  font-size: 12px;
  color: #aeb2b9;
  margin-top: 20px;
  padding-left: 30px;
}
.top10 {
  ::v-deep .el-form-item__error {
    top: 10px;
  }
}
</style>
