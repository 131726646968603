<template>
  <div>
    <!-- <div class="set-content">
    <div class="o-title">店铺装修规则下载</div>
    <el-button icon="el-icon-download" class="button-w-b" size="large" style="width: 100%" @click="download(rule)">立即下载</el-button>
  </div> -->
    <div class="set-content">
      <div class="o-title">用户界面设计素材下载</div>
      <el-button icon="el-icon-download" class="button-w-b" size="large" style="width: 100%" @click="download(material)">立即下载</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Download",
  components: {},
  props: {
    material: {
      type: String,
    },
    // rule: {
    //   type: String,
    // },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    download(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped lang="scss">
.set-content {
  margin: 20px 10px 0;
  background: #f0f2f5;
  padding: 20px 10px;
  border-radius: 4px;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
  text-align: center;
}
</style>
