<template>
  <div class="set-content">
    <div class="o-title">
      <span>分类内容</span>
      <span class="prompt">最多6个</span>
    </div>
    <div class="category-table">
      <div class="one-level" v-for="(oneItem, oneIndex) in classInfo" :key="`sortInfo_${oneIndex}`">
        <div class="line body">
          <div class="td flex align-center" style="width: 150px">
            <i class="iconfont down" :class="{ current: oneItem.open }" @click="oneItem.open = !oneItem.open">&#xe624;</i>
            <el-input v-model="oneItem.store_class_sort" size="small" style="width: 28px" class="category-sort"></el-input>
            <el-input v-model="oneItem.store_class_name" size="small" style="width: 90px; margin-left: 4px" maxlength="40" show-word-limit></el-input>
          </div>
          <div class="td text-center">
            <i class="iconfont move-btn" @click="toTop(oneItem, 'level1', oneIndex)" v-if="oneIndex !== 0">&#xe687;</i>
            <i class="iconfont" v-else></i>
            <i class="iconfont move-btn" @click="toPrev(oneItem, 'level1', oneIndex)" v-if="oneIndex !== 0">&#xe688;</i>
            <i class="iconfont" v-else></i>
            <i class="iconfont move-btn" @click="toEnd(oneItem, 'level1', oneIndex)" v-if="oneIndex !== classInfo.length - 1">&#xe689;</i>
            <i class="iconfont" v-else></i>
            <i class="iconfont move-btn" @click="toNext(oneItem, 'level1', oneIndex)" v-if="oneIndex !== classInfo.length - 1">&#xe68a;</i>
            <i class="iconfont" v-else></i>
          </div>
          <div class="td text-center" style="padding: 0">
            <el-link :underline="false" type="primary" @click="addBtn(oneItem)">添加子分类</el-link>
          </div>
          <div class="td text-center">
            <el-link :underline="false" type="danger" @click="delBtn(oneItem, oneIndex)" icon="el-icon-delete"></el-link>
          </div>
        </div>
        <template v-if="oneItem.children.length > 0">
          <div class="two-level" v-show="oneItem.open">
            <div class="line body" v-for="(twoItem, twoIndex) in oneItem.children" :key="`children_${twoIndex}`">
              <div class="td flex align-center" style="width: 150px">
                <i class="iconfont down" style="margin-left: 10px"></i>
                <el-input v-model="twoItem.store_class_sort" size="small" style="width: 28px" class="category-sort"></el-input>
                <el-input v-model="twoItem.store_class_name" size="small" style="width: 80px; margin-left: 4px" maxlength="40" show-word-limit></el-input>
              </div>
              <div class="td text-center">
                <i class="iconfont move-btn" @click="toTop(twoItem, 'level2', twoIndex, oneItem)" v-if="twoIndex !== 0">&#xe687;</i>
                <i class="iconfont" v-else></i>
                <i class="iconfont move-btn" @click="toPrev(twoItem, 'level2', twoIndex, oneItem)" v-if="twoIndex !== 0">&#xe688;</i>
                <i class="iconfont" v-else></i>
                <i class="iconfont move-btn" @click="toEnd(twoItem, 'level2', twoIndex, oneItem)" v-if="twoIndex !== oneItem.children.length - 1">&#xe689;</i>
                <i class="iconfont" v-else></i>
                <i class="iconfont move-btn" @click="toNext(twoItem, 'level2', twoIndex, oneItem)" v-if="twoIndex !== oneItem.children.length - 1">&#xe68a;</i>
                <i class="iconfont" v-else></i>
              </div>
              <div class="td text-center" style="padding: 0; opacity: 0">添加子分类</div>
              <div class="td text-center">
                <el-link :underline="false" type="danger" @click="delBtn(twoItem, twoIndex, oneItem)" icon="el-icon-delete"></el-link>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <el-button class="button-w-b" size="large" style="width: 100%; margin-top: 10px" @click="addBtn(null)" icon="el-icon-plus">添加分类</el-button>
  </div>
</template>

<script>
import { stuDecorateDelStoreClass } from "@/utils/newDecorate";

export default {
  name: "Sort",
  components: {},
  props: {
    classInfo: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    // 添加
    addBtn(row) {
      if (row) {
        let addItem = {
          store_class_name: "",
        };
        if (row.children.length === 0) {
          addItem.store_class_sort = row.children.length + 1;
        } else {
          addItem.store_class_sort = Number(row.children[row.children.length - 1].store_class_sort) + 1;
        }
        row.children.push(addItem);
      } else {
        if (this.classInfo.length > 5) {
          return this.$message.warning("最多添加6个");
        }
        let listTmp = this.classInfo;
        let addItem = {
          store_class_name: "",
          store_class_sort: null,
          open: true,
          children: [],
        };
        if (this.classInfo.length === 0) {
          addItem.store_class_sort = this.classInfo.length + 1;
        } else {
          addItem.store_class_sort = Number(this.classInfo[this.classInfo.length - 1].store_class_sort) + 1;
        }
        listTmp.push(addItem);
        this.$emit("update:classInfo", listTmp);
      }
    },
    // 置顶
    toTop(row, level, index, oneRow) {
      let cloneRow = JSON.parse(JSON.stringify(row));
      if (level === "level1") {
        this.classInfo.splice(index, 1);
        this.classInfo.unshift(cloneRow);
        sortNumber(this.classInfo);
      } else if (level === "level2") {
        oneRow.children.splice(index, 1);
        oneRow.children.unshift(cloneRow);
        sortNumber(oneRow.children);
      }
    },
    toPrev(row, level, index, oneRow) {
      let cloneRow = JSON.parse(JSON.stringify(row));
      if (level === "level1") {
        this.classInfo.splice(index - 1, 0, cloneRow);
        this.classInfo.splice(index + 1, 1);
        sortNumber(this.classInfo);
      } else if (level === "level2") {
        oneRow.children.splice(index - 1, 0, cloneRow);
        oneRow.children.splice(index + 1, 1);
        sortNumber(oneRow.children);
      }
    },
    // 置底
    toEnd(row, level, index, oneRow) {
      let cloneRow = JSON.parse(JSON.stringify(row));
      if (level === "level1") {
        this.classInfo.splice(index, 1);
        this.classInfo.push(cloneRow);
        sortNumber(this.classInfo);
      } else if (level === "level2") {
        oneRow.children.splice(index, 1);
        oneRow.children.push(cloneRow);
        sortNumber(oneRow.children);
      }
    },
    // 下移
    toNext(row, level, index, oneRow) {
      let cloneRow = JSON.parse(JSON.stringify(row));
      if (level === "level1") {
        this.classInfo.splice(index + 2, 0, cloneRow);
        this.classInfo.splice(index, 1);
        sortNumber(this.classInfo);
      } else if (level === "level2") {
        oneRow.children.splice(index + 2, 0, cloneRow);
        oneRow.children.splice(index, 1);
        sortNumber(oneRow.children);
      }
    },

    // 排序
    sortNumber(arr) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].store_class_sort = i + 1;
      }
    },

    // 删除
    delBtn(row, index, oneRow) {
      if (row.id) {
        this.$confirm("确定删除该数据，删除之后则无法恢复？", "删除数据", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            try {
              stuDecorateDelStoreClass({ id: row.id }).then(({ data, msg }) => {
                this.$message.success(msg);
                this.$emit("getClassList");
              });
            } catch (e) {
              this.$message.error(e);
            }
          })
          .catch(() => {
            console.log("取消");
          });
      } else {
        if (row.hasOwnProperty("open")) {
          this.classInfo.splice(index, 1);
          sortNumber(this.classInfo);
        } else {
          oneRow.children.splice(index, 1);
          sortNumber(oneRow.children);
        }
      }
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.set-content {
  margin: 20px 10px 0;
  background: #f0f2f5;
  padding: 20px 10px;
  border-radius: 4px;
}
.o-title {
  color: #606266;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .prompt {
    font-size: 12px;
    color: #9797a1;
  }
}

.category-table {
  width: 100%;
  .iconfont {
    font-size: 12px;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    color: #67c23a;
    cursor: pointer;
  }
  .line {
    display: flex;
    align-items: center;
    &.body {
      height: 40px;
      margin-bottom: 5px;
    }
    .td {
      display: flex;
      padding: 0 5px;
      .down {
        cursor: pointer;
        color: #7d7d7d;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        margin-right: 4px;
        &.current {
          transform: rotate(90deg);
        }
      }
      .placeIcon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }
    &:hover {
      background: #fafafa;
    }
  }
  .category-sort {
    ::v-deep .el-input__inner {
      text-align: center;
      padding: 0;
    }
  }
}
</style>
